// generalRedirections.js
export const generalRedirectionMap = {
  "/programs/pgcp?page=1": "/all-programs",
  "/programs/certifications?page=1": "/all-programs",
  "/programs/masters?page=4": "/all-programs",
  "/programs/masters?page=1": "/all-programs",
  "/sites/default/files/2022-02/mba_project_management.pdf": "/sites/default/files/2022-11/MBA-Project-Management.pdf",
  "/sites/default/files/2022-02/bba_engineering_and_project_management.pdf": "/pdf/BBA-Engineering-and-Project-Management.pdf",
  "/sites/default/files/2022-02/mcom_international_finance.pdf": "/sites/default/files/2022-11/MBA-International-Finance-(Accredited-by-ACCA-UK).pdf",
  "/programs/masters/mba-advertising-and-branding-degree-online": "/master-business-administration-online-degree",
  "/programs/masters/online-master-of-business-administration-degree": "/master-business-administration-online-degree",
  "/programs/masters/mba-strategic-human-resource-management-degree-online": "/online-mba/strategic-finance",
  "/brochure/mba/blogs/deciding-between-bba-and-bcom-which-is-the-ideal-choice-post-12th": "/blogs",
  "/brochure/mba/blogs/a-guide-to-data-analytics-and-simpy-for-business-optimization": "/blogs",
  "/brochure/mba/blogs/real-world-applications-of-bba-marketing-concepts": "/blogs",
  "/programs/masters/ma-degree-online": "/master-of-arts-online-degree",
  "/brochure/mca/blogs/beyond-python-and-r-exploring-julia-for-high-performance-data-workflows": "/blogs",
  "/brochure/mba/blogs/beyond-python-and-r-exploring-julia-for-high-performance-data-workflows": "/blogs",
  "/programs/certifications?q=jain/programs/certifications&page=1": "/micro-credentials-online-courses",
  "/programs/bachelors?page=2": "/all-programs",
  "/index.php/programs/masters?page=3": "/all-programs",
  "/sites/default/files/2022-02/mca_game_development.pdf": "/masters-computer-application-online-degree",
  "/sites/default/files/program_brochure/Program-Brochure-MBA_0.pdf": "/pdf/Program-Brochure-MBA.pdf",
  "/JIVA.pdf": "/onlinejain.com",
  "/index.php/mba-finance-degree-online": "/online-mba/finance",
  "/index.php/mca-data-analytics-degree-online": "/online-mca/data-analytics",
  "/programs/master/mba-retail-banking-operations-degree-online": "/online-mba/retail-banking-operations",
  "/sites/default/files/program_brochure/ma_program_brochure.pdf": "/pdf/Program-Brochure-MA.pdf",
  "/master-business-administration-online-degree/blogs/career-opportunities-in-accounting-and-finance-the-role-of-mcom-program": "/blogs",
  "/index.php/ma-public-policy-and-administration-degree-online": "/online-ma/public-policy",
  "/index.php/mba-human-resource-management-degree-online": "/online-mba/human-resources-management",
  "/online-mca/bachelor-of-commerce-online-degree": "/masters-computer-application-online-degree",
  "/brochure/ma/blogs/top-5-case-studies-every-mba-project-management-student-should-explore": "/blogs",
  "/index.php/bba-digital-marketing-degree-online": "/online-bba/digital-marketing",
  "/brochure/ma/blogs/celebrating-mothers-who-juggle-it-all-happy-mothers-day": "/blogs",
  "/programs/master/mba-digital-finance-degree-online": "/online-mba/finance",
  "/sites/default/files/2022-01/bba_finance.pdf": "/sites/default/files/2022-11/BBA-Finance.pdf",
  "/master-business-administration-online-degree/blogs/social-media-marketing-tips-from-mba-marketing-graduates": "/blogs",
  "/index.php/bcom-professional-accounting-and-finance-degree-online": "/online-bcom/accounting-and-finance",
  "/sites/default/files/2023-07/Theory-Examination-Time-Table-For-Reports.pdf": "/",
  "/brochure/ma/blogs/social-media-marketing-tips-from-mba-marketing-graduates": "/blogs",
  "/master-business-administration-online-degree/blogs/celebrating-mothers-who-juggle-it-all-happy-mothers-day": "/blogs",
  "/sites/default/files/2022-02/mba_marketing_and_human_resource_management.pdf": "/sites/default/files/2022-11/MBA-Marketing-and-Human-Resource-Management.pdf",
  "/brochure/ma/blogs/career-opportunities-in-accounting-and-finance-the-role-of-mcom-program": "/blogs",
  "/sites/default/files/program_brochure/Program-Brochure-MA_0.pdf": "/pdf/Program-Brochure-MA.pdf",
  "/blog/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/default/files/2023-10/Calendar-of-Events-All-Batches.pdf": "/",
  "/sites/default/files/2023-10/JAIN-Online-TnC-docx.pdf": "/media/JAIN-Online-TnC-docx.pdf",
  "/blog/mastering-full-stack-development-key-skills-and-learning-pathways": "/blogs",
  "/sites/default/files/2023-11/Calendar-of-Events-Post-Graduate-Programs.pdf": "/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  "/sites/default/files/2023-11/Calendar-of-Events-Under-Graduate-Programs.pdf": "/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  "/sites/default/files/2022-02/mba_healthcare_management.pdf": "/sites/default/files/2022-11/MBA-Healthcare-Management.pdf",
  "/online-mba/blog/beyond-boundaries-global-opportunities-online-mca-education": "/blogs",
  "/sites/default/files/2023-12/Calendar-of-Events-Post-Graduate-Programs.pdf": "/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  "/brochure/ma/blog/beyond-boundaries-global-opportunities-online-mca-education": "/master-of-arts-online-degree",
  "/blog/10-reasons-pursue-online-certification-programs-entrepreneurship-development": "/blogs",
  "/sites/default/files/2021-12/Finance_0.pdf": "/sites/default/files/2022-11/MBA-Finance.pdf",
  "/programs/masters/mca-cyber-security-collaboration-with-TCS-degree-online": "/online-mca/cyber-security",
  "/sites/default/files/program_brochure/Program-Brochure-MCA.pdf": "/pdf/Program-Brochure-MCA.pdf",
  "/programs/master/mba-lean-operations-management-degree-online": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/Marketing_0.pdf": "/sites/default/files/2022-11/MBA-Marketing.pdf",
  "/sites/default/files/2024-01/22-Jan-Calendar-of-Events-Post-Graduate-Programs.pdf": "/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  "/sites/default/files/2024-01/Calendar-of-Events-Post-Graduate-Programs.pdf": "/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  "/sites/default/files/2022-02/mba_international_finance.pdf": "/sites/default/files/2022-11/MBA-International-Finance-(Accredited-by-ACCA-UK).pdf",
  "/programs/masters/mca-cyber-security-degree-online": "/online-mca/cyber-security",
  "/sites/default/files/2024-01/Calendar-of-Events-Under-Graduate-Programs.pdf": "/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  "/brochure/mcom/blog/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/programs/bachelors/bba-banking-and-finance-degree-online": "/online-bba/finance",
  "/genetics-certification-online": "/micro-credentials-online-courses",
  "/sites/default/files/2024-02/Calendar-of-Events-Post-Graduate-2024.pdf": "/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  "/blog/why-corporate-accounting-is-a-good-career-option": "/blogs",
  "/sites/default/files/2024-02/Calendar-of-Events-Under-Graduate-2024.pdf": "/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  "/blog/role-strategic-human-resource-management-organizational-success": "/blogs",
  "/sites/default/files/2024-03/Calendar-of-Events-Post-Graduate-2024_0.pdf": "/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  "/master-business-administration-online-degree/blog/beyond-boundaries-global-opportunities-online-mca-education": "/blogs",
  "/sites/default/files/2024-03/Calendar-of-Events-Under-Graduate-2024.pdf": "/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  "/brochure/ma/blogs/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/master-business-administration-online-degree/blogs/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/blog/explore-significance-bcom-international-finance-and-accounting-program/": "/blogs",
  "/brochure/bba/blogs/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/brochure/ma/blogs/top-10-job-opportunities-after-mba-healthcare-management": "/blogs",
  "/blog/beyond-boundaries-global-opportunities-online-mca-education": "/blogs",
  "/sites/default/files/2022-02/mca_cloud_computing.pdf": "/sites/default/files/2022-11/MCA-Cloud-Computing.pdf",
  "/blogs/evolving-landscape-healthcare-management-education": "/blogs",
  "/blog/top-10-final-year-mba-marketing-projects-boost-your-career": "/blogs",
  "/programs/masters/mba-business-and-artificial-intelligence-degree-online": "/master-business-administration-online-degree",
  "/mba-marketing-and-human-resource-management-degree-online": "/online-mba/hr-marketing",
  "/sites/default/files/program_brochure/Program-Brochure-BBA.pdf": "/pdf/Program-Brochure-BBA.pdf",
  "/sites/default/files/program_brochure/Program-Brochure-MA.pdf": "/pdf/Program-Brochure-MA.pdf",
  "/blogs/how-online-mca-data-science-programs-accelerate-career-growth": "/blogs",
  "/sites/default/files/2022-02/mba_banking_finance.pdf": "/sites/default/files/2022-11/MBA-Banking-and-Finance.pdf",
  "/blog/top-10-job-opportunities-after-mba-healthcare-management/": "/blogs",
  "/sites/default/files/2021-12/Marketing.pdf": "/sites/default/files/2022-11/MBA-Marketing.pdf",
  "/sites/default/files/2024-03/DEB-Public-Notice-Precautions-to-be-taken-by-students.pdf": "/",
  "/blog/top-10-job-opportunities-after-mba-healthcare-management": "/blogs",
  "/blogs/other?page=1": "/blogs",
  "/sites/default/files/media_images/media_kit_brand_guidelines.pdf": "/",
  "/blogs/how-online-degree-program-going-survive-longer-run": "/blogs",
  "/index.php/cancellation-and-refund-rules": "/refund-policy",
  "/sites/default/files/2022-02/mba_marketing.pdf": "/sites/default/files/2022-11/MBA-Marketing.pdf",
  "/index.php/programs/certifications":  "/all-programs",
  "/sites/default/files/2021-12/Aviation%20Management.pdf": "/bachelor-of-business-administration-online-degree",
  "/sites/default/files/2021-12/Aviation Management.pdf": "/bachelor-of-business-administration-online-degree",
  "/sites/default/files/2021-12/International%20Marketing.pdf": "/sites/default/files/2022-11/MBA-Marketing.pdf",
  "/sites/default/files/2021-12/International Marketing.pdf": "/sites/default/files/2022-11/MBA-Marketing.pdf",
  "/sites/default/files/2021-12/Aviation%20Management_0.pdf": "/bachelor-of-business-administration-online-degree",
  "/sites/default/files/2021-12/Aviation Management_0.pdf": "/bachelor-of-business-administration-online-degree",
  "/sites/default/files/2021-12/International%20Finance_0.pdf": "",
  "/sites/default/files/2021-12/International Finance_0.pdf": "",
  "/sites/default/files/2021-12/Investment%20Banking%20_%20Equity%20Research.pdf": "",
  "/sites/default/files/2021-12/Investment Banking _ Equity Research.pdf": "",
  "/sites/default/files/2021-12/Marketing%20and%20Human%20Resource%20Management.pdf": "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  "/sites/default/files/2021-12/Marketing and Human Resource Management.pdf": "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  "/sites/default/files/2021-12/Strategy%20_%20Leadership.pdf": "",
  "/sites/default/files/2021-12/Strategy _ Leadership.pdf": "",
  "/sites/default/files/2021-12/Corporate%20Secretaryship.pdf": "/",
  "/sites/default/files/2021-12/Corporate Secretaryship.pdf": "/",
  "/sites/default/files/2021-12/Corporate%20Accounting.pdf": "/",
  "/sites/default/files/2021-12/Corporate Accounting.pdf": "/",
  "/sites/default/files/2021-12/General%20Management%20.pdf": "/sites/default/files/2022-11/MBA-General-Management.pdf",
  "/sites/default/files/2021-12/General Management .pdf": "/sites/default/files/2022-11/MBA-General-Management.pdf",
  "/sites/default/files/2021-12/Artificial%20Intelligence.pdf": "/sites/default/files/2022-11/MCA-%20Artificial-Intelligence.pdf",
  "/sites/default/files/2021-12/Artificial Intelligence.pdf": "/sites/default/files/2022-11/MCA-%20Artificial-Intelligence.pdf",
  "/sites/default/files/2021-12/Finance%20and%20Leadership_0.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/Finance and Leadership_0.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/Entrepreneurship%20and%20Leadership.pdf": "/pdf/MBA-Entrepreneurship-and-Leadership.pdf",
  "/sites/default/files/2021-12/Entrepreneurship and Leadership.pdf": "/pdf/MBA-Entrepreneurship-and-Leadership.pdf",
  "/sites/default/files/2021-12/Financial%20Markets_0.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/Financial Markets_0.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/International%20Finance%20_%20Accounting.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/International Finance _ Accounting.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/International Marketing": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/International%20Marketing": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/Strategic Finance .pdf": "/sites/default/files/2022-11/MBA-Strategic-Finance-(Accredited-by-CPA-US+CMA-US ).pdf",
  "/sites/default/files/2021-12/Strategic%20Finance%20.pdf": "/sites/default/files/2022-11/MBA-Strategic-Finance-(Accredited-by-CPA-US+CMA-US ).pdf",
  "/sites/default/files/2021-12/Information Technology Management.pdf": "/sites/default/files/2022-11/MBA-Information-Technology-Management.pdf",
  "/sites/default/files/2021-12/Information%20Technology%20Management.pdf": "/sites/default/files/2022-11/MBA-Information-Technology-Management.pdf",
  "/sites/default/files/2021-12/Logistics _ Supply Chain Management.pdf": "/sites/default/files/2022-11/MBA-Logistics-and-Supply-Chain-Management.pdf",
  "/sites/default/files/2021-12/Logistics%20_%20Supply%20Chain%20Management.pdf": "/sites/default/files/2022-11/MBA-Logistics-and-Supply-Chain-Management.pdf",
  "/sites/default/files/2021-12/Professional Accounting Finance.pdf": "/sites/default/files/2022-11/M.Com-Professional-Accounting-and-Finance-(Accredited-by-CPA-US)_compressed.pdf",
  "/sites/default/files/2021-12/Professional%20Accounting%20Finance.pdf": "/sites/default/files/2022-11/M.Com-Professional-Accounting-and-Finance-(Accredited-by-CPA-US)_compressed.pdf",
  "/sites/default/files/2021-12/Human Resource Management_0.pdf": "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  "/sites/default/files/2021-12/Human%20Resource%20Management_0.pdf": "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  "/sites/default/files/2021-12/Healthcare Management .pdf": "/sites/default/files/2022-11/MBA-Healthcare-Management.pdf",
  "/sites/default/files/2021-12/Healthcare%20Management%20.pdf": "/sites/default/files/2022-11/MBA-Healthcare-Management.pdf",
  "/sites/default/files/2021-12/Project Management.pdf": "/sites/default/files/2022-11/MBA-Project-Management.pdf",
  "/sites/default/files/2021-12/Project%20Management.pdf": "/sites/default/files/2022-11/MBA-Project-Management.pdf",
  "/sites/default/files/2021-12/International Finance.pdf":"/sites/default/files/2022-11/MBA-International-Finance-(Accredited-by-ACCA-UK).pdf",
  "/sites/default/files/2021-12/International%20Finance.pdf": "/sites/default/files/2022-11/MBA-International-Finance-(Accredited-by-ACCA-UK).pdf",
  "/sites/default/files/2021-12/Human Resource Management and Finance.pdf": "/sites/default/files/2022-11/MBA-Human-Resource-Management-and-Finance.pdf",
  "/sites/default/files/2021-12/Human%20Resource%20Management%20and%20Finance.pdf": "/sites/default/files/2022-11/MBA-Human-Resource-Management-and-Finance.pdf",
  "/sites/default/files/2022-03/MBA-Human Resource Management_0.pdf": "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  "/sites/default/files/2022-03/MBA-Human%20Resource%20Management_0.pdf": "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  "/index.php/mba-banking-and-finance- degree-online": "/online-mba/banking-and-finance",
  "/index.php/mba-banking-and-finance-%20degree-online": "/online-mba/banking-and-finance",
  "/index.php/mba-logistics-and-supply-chain-management- degree-online": "/online-mba/logistic-and-supply-chain-management",
  "/index.php/mba-logistics-and-supply-chain-management-%20degree-online": "/online-mba/logistic-and-supply-chain-management",
  "/index.php/mcom-international-finance-degree online": "/online-mcom/international-finance",
  "/index.php/mcom-international-finance-degree%20online": "/online-mcom/international-finance",
  "/sites/default/files/2024-01/22-Jan- Calendar-of-Events-Under-Graduate-Programs.pdf": "/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  "/sites/default/files/2024-01/22-Jan-%20Calendar-of-Events-Under-Graduate-Programs.pdf": "/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  "/sites/default/files/2021-12/Banking and Finance.pdf": "/sites/default/files/2022-11/MBA-Banking-and-Finance.pdf",
  "/sites/default/files/2021-12/Banking%20and%20Finance.pdf": "/sites/default/files/2022-11/MBA-Banking-and-Finance.pdf",
  "/sites/default/files/2021-12/Data Analytics.pdf": "/pdf/MCA-Data-Analytics-(Accredited-by-IoA,UK).pdf",
  "/sites/default/files/2021-12/Data%20Analytics.pdf": "/pdf/MCA-Data-Analytics-(Accredited-by-IoA,UK).pdf",
  "/sites/default/files/2021-12/Cloud Computing.pdf": "/sites/default/files/2022-11/MCA-Cloud-Computing.pdf",
  "/sites/default/files/2021-12/Cloud%20Computing.pdf": "/sites/default/files/2022-11/MCA-Cloud-Computing.pdf",
  "/sites/default/files/2021-12/Data Science.pdf": "/sites/default/files/2022-11/MCA-Data-Science.pdf",
  "/sites/default/files/2021-12/Data%20Science.pdf": "/sites/default/files/2022-11/MCA-Data-Science.pdf",
  "/sites/default/files/2021-12/Computer Science _ IT.pdf": "/sites/default/files/2022-11/MCA-Computer-Science-and-IT.pdf",
  "/sites/default/files/2021-12/Computer%20Science%20_%20IT.pdf": "/sites/default/files/2022-11/MCA-Computer-Science-and-IT.pdf",
  "/sites/default/files/2021-12/Banking _ Finance.pdf": "/sites/default/files/2022-11/MBA-Banking-and-Finance.pdf",
  "/sites/default/files/2021-12/Banking%20_%20Finance.pdf": "/sites/default/files/2022-11/MBA-Banking-and-Finance.pdf",
  "/sites/default/files/2021-12/Accounting and Finance.pdf": "/sites/default/files/2022-11/M.Com-Accounting-and-Finance_compressed.pdf",
  "/sites/default/files/2021-12/Accounting%20and%20Finance.pdf": "/sites/default/files/2022-11/M.Com-Accounting-and-Finance_compressed.pdf"
};
export function getRedirectPath(currentPath) {
  return generalRedirectionMap[currentPath] || null;
}